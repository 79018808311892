import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import { withRouter } from "../withRouter";
import {
  authVoterToEvent,
  createVote,
  getCurrentStatement,
  getCurrentStatementForVoter,
  getEventByUrl,
  getEventColors,
  getStatementByOrder,
  getVote,
  getVoter,
  updateCurrentStatementNumber,
  updateUserStatus,
} from "../api";
import Badge from "react-bootstrap/Badge";
import { QuestionCircleFill } from "react-bootstrap-icons";
import { Toast } from "react-bootstrap";

import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { withTheme } from "../withTheme";
import bandesPhoviaH from "../assets/bandes_phovia_h.svg";
import VoterNavBar from "./VoterNavBar.js";
import { BASE_URL } from "../config";
import LoadingButton from "./LoadingButton";
import { Spring, animated } from "@react-spring/web";
import Form from "react-bootstrap/Form";

class PresenterSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      user: null,
      current_statement: null,
      waiting: true,
      loading: true,
      finished: false,
      idVote: null,
      showToast: false,
      comments: "",
      error: false,
    };
    this.vh = window.innerHeight * 0.01;
    // Store bound methods as instance properties for proper event listener removal
    this.boundLoadPage = this.loadPage.bind(this);
    this.boundSetVHValue = this.setVHValue.bind(this);
  }

  showAndHideToast = () => {
    this.setState({ showToast: true }, () => {
      setTimeout(() => {
        this.setState({ showToast: false });
      }, 3000);
    });
  };

  generateCircles() {
    const translations = {
      stronglyDisagree:
        this.state.event.langage === "fr"
          ? "Pas du tout d'accord"
          : "Strongly disagree",
      stronglyAgree:
        this.state.event.langage === "fr"
          ? "Tout à fait d'accord"
          : "Strongly agree",
      noOpinion:
        this.state.event.langage === "fr" ? "Sans opinion" : "No opinion",
    };

    return (
      <div
        className="mt-3"
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignSelf: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {Array.from({ length: 9 }, (_, index) => (
            <Spring
              key={index}
              borderColor={
                this.state.hoverIndex == index
                  ? this.state.colors?.header_background_color
                  : "transparent"
              }
            >
              {(props) => (
                <animated.div
                  onClick={() => {
                    this.setState({ idVote: index });
                  }}
                  key={index}
                  onMouseEnter={() => {
                    this.setState({ hoverIndex: index });
                  }}
                  onMouseLeave={() => {
                    this.setState({ hoverIndex: null });
                  }}
                  style={{
                    ...props,
                    cursor: "pointer",
                    width: "calc(min(480px, 100vw) / 9 - 8px)",
                    height: "calc(min(480px, 100vw) / 9 - 8px)",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0px 2px 20px -10px rgba(0,0,0,0.2)",
                    fontWeight: this.state.idVote === index ? "bold" : "normal",
                    border: "2px solid",

                    color:
                      index === this.state.idVote
                        ? "var(--light)"
                        : "var(--dark)",
                    backgroundColor:
                      index === this.state.idVote
                        ? this.state.colors?.header_background_color
                        : "var(--light)",
                  }}
                >
                  {index + 1}
                </animated.div>
              )}
            </Spring>
          ))}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            className="fw fs-7"
            style={{ textAlign: "left", width: 100, fontWeight: "bold" }}
          >
            {translations.stronglyDisagree}
          </div>
          <div
            className="fw fs-7"
            style={{ textAlign: "right", width: 100, fontWeight: "bold" }}
          >
            {translations.stronglyAgree}
          </div>
        </div>
        {this.state.event?.with_no_opinion && (
          <div
            onClick={() => {
              this.setState({ idVote: -1 });
            }}
            className="mt-3"
            style={{
              cursor: "pointer",
              height: "calc(min(480px, 100vw) / 9 - 8px)",
              borderRadius: "calc((min(480px, 100vw) / 9 - 8px)/2) ",
              width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 2px 20px -10px rgba(0,0,0,0.2)",
              fontWeight: this.state.idVote === -1 ? "bold" : "normal",

              color: -1 === this.state.idVote ? "var(--light)" : "var(--dark)",
              backgroundColor:
                -1 === this.state.idVote
                  ? this.state.colors?.header_background_color
                  : "var(--light)",
            }}
          >
            {translations.noOpinion}
          </div>
        )}
      </div>
    );
  }

  async handleVote() {
    if (this.state.idVote !== null) {
      try {
        await createVote(this.state.event.id, this.state.current_statement.id, {
          vote_int: this.state.idVote + 1,
          date_of_vote: new Date().toISOString(),
          comments: this.state.comments,
        });
        this.showAndHideToast();
        this.setState({ comments: "" });
        if (!this.state.event.with_presenter) {
          try {
            const nextStatement = await getStatementByOrder(
              this.state.event.id,
              this.state.current_statement.order_number + 1
            );

            await updateCurrentStatementNumber(
              this.state.event.id,
              nextStatement.order_number
            );
            let vote = null;
            try {
              vote = await getVote(this.state.event.id, nextStatement.id);
            } catch (e) {
              console.log(e);
            }
            this.setState({
              current_statement: nextStatement,
              idVote: vote ? vote.vote_int - 1 : null,
              comments: "",
            });
          } catch (error) {
            if (error.response && error.response.status === 404) {
              if (this.state.event.form_placement == "start") {
                await updateUserStatus(this.state.event.id, "finished");
              }
              if (
                this.state.event.form_placement == "end" &&
                this.state.user.answers?.length == 0
              )
                this.props.navigate(
                  "/events/" + this.props.params.unique_str + "/voter/register",
                  {
                    state: {
                      event_id: this.state.event.id,

                      langage: this.state.event.langage,
                      form_placement: this.state.event.form_placement,
                      position: "end",
                      unique_str: this.props.params.unique_str,
                    },
                  }
                );
              this.setState({ finished: true });
            } else {
              throw error;
            }
          }
        } else this.setState({ waiting: true });
      } catch (e) {}
    }
  }

  async handlePrevious() {
    const prev = await getStatementByOrder(
      this.state.event.id,
      this.state.current_statement.order_number - 1
    );
    const vote = await getVote(this.state.event.id, prev.id);
    this.setState({
      current_statement: prev,
      idVote: vote.vote_int - 1,
      comments: vote.comments,
    });
  }

  async loadPage() {
    try {
      this.setState({ loading: true });
      const event = await getEventByUrl(this.props.params.unique_str);

      // Restore the focus event listener
      if (event.with_presenter)
        window.addEventListener("focus", this.boundLoadPage);

      const colors = await getEventColors(event.id);
      this.setState({ colors });
      if (!event.with_presenter) {
        await authVoterToEvent(event.id);
        const user = await getVoter(event.id);
        this.setState({ user, event, idVote: null, with_presenter: false });
        if (user.event_status === "finished") {
          if (event.form_placement == "end" && user.answers?.length == 0)
            this.props.navigate(
              "/events/" + this.props.params.unique_str + "/voter/register",
              {
                state: {
                  event_id: this.state.event.id,

                  langage: this.state.event.langage,
                  form_placement: this.state.event.form_placement,
                  position: "end",
                  unique_str: this.props.params.unique_str,
                },
              }
            );
          this.setState({ finished: true, loading: false });
        } else {
          const current_statement = await getCurrentStatementForVoter(event.id);
          let vote;
          try {
            vote = await getVote(event.id, current_statement.id);
          } catch (e) {
            console.log(e);
          }
          this.setState({
            current_statement,
            comments: vote ? vote.comments : "",
            loading: false,
            waiting: false,
            idVote: vote ? vote.vote_int - 1 : null,
          });
        }
        this.setState({ loading: false });
      } else {
        // Only create WebSocket if it doesn't exist or is closed
        if (!this.websocket || this.websocket.readyState === WebSocket.CLOSED) {
          this.createWebSocket(event.id);
        }
        await authVoterToEvent(event.id);
        const user = await getVoter(event.id);
        this.setState({ user, event, idVote: null });
        if (event.status === "finished") {
          if (event.form_placement == "end" && user.answers?.length == 0)
            this.props.navigate(
              "/events/" + this.props.params.unique_str + "/voter/register",
              {
                state: {
                  event_id: event.id,
                  langage: event.langage,
                  form_placement: event.form_placement,
                  position: "end",
                },
              }
            );
          this.setState({ finished: true, loading: false });
          return;
        }
        if (event.status === "to come" || event.pause) {
          this.setState({ waiting: true });
        } else {
          const current_statement = await getCurrentStatement(event.id);
          try {
            await getVote(event.id, current_statement.id);
          } catch (e) {
            this.setState({ waiting: false });
          }
          this.setState({ current_statement });
        }
        this.setState({ loading: false });
      }
    } catch (e) {
      let error_message;

      // Checking if the response is available from the server
      if (e.response) {
        // Handle specific status codes
        switch (e.response.status) {
          case 400:
            this.props.navigate(
              "/events/" + this.props.params.unique_str + "/voter"
            );
            return; // exit from the function
          case 401:
            // Handle 401 Unauthorized response from the server
            this.props.navigate(
              "/events/" + this.props.params.unique_str + "/voter"
            );
            return;
          case 404:
            this.props.navigate(
              "/events/" + this.props.params.unique_str + "/voter"
            );
          default:
            // Display the error detail from the server
            error_message =
              e.response.data.detail || "An unexpected error occurred.";
            break;
        }
      } else {
        console.log(e);
        // Handle the scenario where there's no response (likely a network/connection error)
        error_message = "A connection error occurred. Please try again later.";
      }

      // Set the state to reflect the error
      this.setState({ error: true, error_message, loading: false });
    }
  }
  setVHValue = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  async componentDidMount() {
    // Replace this URL with the correct endpoint URL
    this.setVHValue();
    window.addEventListener("resize", this.boundSetVHValue);

    this.loadPage();
  }

  createWebSocket(event_id) {
    // Close any existing websocket before creating a new one
    if (this.websocket) {
      // Only attempt to close if the connection is not already closed
      if (this.websocket.readyState !== WebSocket.CLOSED) {
        this.websocket.close();
      }
      // Remove the reference to the old websocket
      this.websocket = null;
    }

    const connect = () => {
      // Don't reconnect if component is unmounting
      if (this.isUnmounting) {
        return;
      }

      const cleanBaseURL = BASE_URL.replace(/^https?:\/\//, "");
      this.websocket = new WebSocket(
        `wss://${cleanBaseURL}/ws/event/${event_id}`
      );

      this.websocket.onopen = () => {
        console.log("WebSocket connection opened");
        // Reset reconnection attempts on successful connection
        this.reconnectAttempts = 0;
      };

      this.websocket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        switch (message.action) {
          // Handle different actions here
          case "launch_event":
            this.setState({ waiting: false });
            this.fetchNewStatement();
            break;
          case "finish_voting":
            this.setState({ waiting: true });
            break;
          case "pause_event":
            this.setState({ waiting: true });
            break;
          case "next_statement":
            this.setState({ waiting: false });
            this.fetchNewStatement();
            break;
          case "finish_event":
            if (this.state.event.form_placement == "end")
              this.props.navigate(
                "/events/" + this.props.params.unique_str + "/voter/register",
                {
                  state: {
                    event_id: this.state.event.id,
                    langage: this.state.event.langage,
                    form_placement: this.state.event.form_placement,
                    position: "end",
                  },
                }
              );
            this.setState({ finished: true });
            break;
          default:
            console.log("Unknown action:", message.action);
        }
      };

      this.websocket.onclose = (event) => {
        console.log("WebSocket connection closed", event.code);

        // If the connection was not closed cleanly and component is not unmounting
        if (event.code !== 1000 && !this.isUnmounting) {
          // Implement exponential backoff for reconnection
          this.reconnectAttempts = (this.reconnectAttempts || 0) + 1;
          const delay = Math.min(
            5000 * Math.pow(1.5, this.reconnectAttempts - 1),
            30000
          );

          console.log(
            `Reconnecting in ${delay / 1000} seconds... (attempt ${
              this.reconnectAttempts
            })`
          );
          this.reconnectTimeout = setTimeout(connect, delay);
        }
      };

      this.websocket.onerror = (error) => {
        console.error("WebSocket Error:", error);
        // Don't close the connection here, let the onclose handler handle reconnection
      };
    };

    connect(); // Initiating the connection
  }

  componentWillUnmount() {
    // Set flag to prevent reconnection attempts
    this.isUnmounting = true;

    // Clear any pending reconnect timeout
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }

    // Remove focus event listener
    window.removeEventListener("focus", this.boundLoadPage);
    window.removeEventListener("resize", this.boundSetVHValue);

    // Close the WebSocket connection when the component is unmounted
    if (this.websocket) {
      this.websocket.close();
      this.websocket = null;
    }
  }

  formatStatement(statement) {
    return statement.split("<br/>").map((text, index, array) => (
      <>
        {text}
        {/* Render a <br /> for all but the last segment */}
        {index === array.length - 1 ? null : <br />}
      </>
    ));
  }

  async fetchNewStatement() {
    try {
      this.setState({ loading: true, idVote: null });
      const current_statement = await getCurrentStatement(this.state.event.id);
      this.setState({ current_statement, loading: false });
    } catch (e) {}
  }

  renderPage() {
    const translations = {
      endOfVote:
        this.state.event?.project_id == 10 || this.state.event?.project_id == 16
          ? this.state.event?.langage === "fr"
            ? "Nous vous remercions grandement d'avoir pris le temps de remplir ce questionnaire. <br/><br/> Vos votes et commentaires seront très utiles pour améliorer les recommandations établies dans le cadre de ce consensus Delphi.<br/><br/>Pour rappel, ces recommandations feront l'objet, par la suite, d'une publication dans une revue scientifique à Comité de Lecture.<br/><br/>Le Comité Scientifique et de Pilotage*.<br/><br/>*Prs / Drs Marie-Noelle DELYFER, Bénédicte DUPAS, Jean-François GIRMENS, Michel PAQUES et Stéphanie BAILLIF, Mohamed BENNANI, Corinne DOT, Vincent GUALINO et Pascale MASSIN."
            : "We thank you very much for taking the time to fill out this questionnaire. <br/><br/> Your votes and comments will be very useful to improve the recommendations established as part of this Delphi consensus.<br/><br/>As a reminder, these recommendations will subsequently be published in a scientific journal with Peer Review. <br/><br/>The Scientific and Steering Committee*.<br/><br/>*Prs / Drs Marie-Noelle DELYFER, Bénédicte DUPAS, Jean-François GIRMENS, Michel PAQUES et Stéphanie BAILLIF, Mohamed BENNANI, Corinne DOT, Vincent GUALINO et Pascale MASSIN."
          : this.state.event?.project_id == 17
          ? this.state.event?.langage === "fr"
            ? "Nous vous remercions grandement d'avoir pris le temps de remplir ce questionnaire. <br/><br/> Vos votes seront très utiles pour améliorer les recommandations établies dans le cadre de ce consensus Delphi. <br/><br/>Pour rappel, ces recommandations feront l'objet, par la suite, d'une publication dans une revue scientifique à Comité de Lecture.<br/><br/>*Dr Sylvie ROYANT-PAROLA, Dr Marjorie GUILLET,  <br/>Pr Yves DAUVILLIERS, Pr Pierre-Alexis GEOFFROY, <br/>Pr Damien LÉGER, Dr Jean-Bastien MICOULAUD et <br/>Pr Jean-Louis PÉPIN."
            : "We thank you very much for taking the time to fill out this questionnaire. <br/><br/> Your votes will be very useful to improve the recommendations established as part of this Delphi consensus.<br/><br/>As a reminder, these recommendations will subsequently be published in a scientific journal with Peer Review.<br/><br/>*Dr Sylvie ROYANT-PAROLA, Dr Marjorie GUILLET,  <br/>Pr Yves DAUVILLIERS, Pr Pierre-Alexis GEOFFROY, <br/>Pr Damien LÉGER, Dr Jean-Bastien MICOULAUD et <br/>Pr Jean-Louis PÉPIN."
          : this.state.event?.langage === "fr"
          ? "Nous vous remercions grandement d'avoir pris le temps de remplir ce questionnaire. <br/><br/> Vos votes et commentaires seront très utiles pour améliorer les recommandations établies dans le cadre de ce consensus Delphi.<br/><br/>Pour rappel, ces recommandations feront l'objet, par la suite, d'une publication dans une revue scientifique à Comité de Lecture.<br/><br/>Medical Education Corpus au nom du comité de Pilotage*.<br/><br/>*Dr Sylvie ROYANT-PAROLA, Dr Marjorie GUILLET,  <br/>Pr Yves DAUVILLIERS, Pr Pierre-Alexis GEOFFROY, <br/>Pr Damien LÉGER, Dr Jean-Bastien MICOULAUD et <br/>Pr Jean-Louis PÉPIN."
          : "We thank you very much for taking the time to fill out this questionnaire. <br/><br/> Your votes and comments will be very useful to improve the recommendations established as part of this Delphi consensus.<br/><br/>As a reminder, these recommendations will subsequently be published in a scientific journal with Peer Review. <br/><br/>Medical Education Corpus on behalf of the Steering Committee*.<br/><br/>*Dr Sylvie ROYANT-PAROLA, Dr Marjorie GUILLET, <br/>Pr Yves DAUVILLIERS, Pr Pierre-Alexis GEOFFROY, <br/>Pr Damien LÉGER, Dr Jean-Bastien MICOULAUD et <br/>Pr Jean-Louis PÉPIN.",

      contact:
        this.state.event?.langage === "fr"
          ? "Pour toute demande d'information, merci d'envoyer un email à " +
            this.state.event?.event_end_contact
          : "For any information request, please send an email to " +
            this.state.event?.event_end_contact,

      thanksForParticipation:
        this.state.event?.langage === "fr"
          ? "MERCI POUR VOTRE PARTICIPATION"
          : "THANKS FOR YOUR PARTICIPATION",
      pleaseWait:
        this.state.event?.langage === "fr"
          ? "Veuillez attendre le prochain vote..."
          : "Please wait for the next vote...",
      statementToVote:
        this.state.event?.langage === "fr" ? "Assertion" : "Statement to vote",
      howMuchAgree:
        this.state.event?.langage === "fr"
          ? "Que pensez-vous de cette assertion ?"
          : "How much do you agree with this statement ?",
      vote: this.state.event?.langage === "fr" ? "Voter" : "Vote",
      previous: this.state.event?.langage === "fr" ? "Précédent" : "Previous",
      comments:
        this.state.event?.langage === "fr" ? "Commentaires" : "Comments",
      commentsPlaceholder:
        this.state.event?.langage === "fr"
          ? "Entrez vos commentaires ici"
          : "Enter your comments here",

      infoDelphi:
        this.state.event?.langage === "fr"
          ? "Vous avez la possibilité de quitter le vote Delphi en cours à tout moment et de poursuivre votre session plus tard en cliquant sur le lien d'accès que vous avez reçu."
          : "You have the possibility to leave the Delphi vote in progress at any time and to continue your session later by clicking on the access link that you received.",

      saveAndQuit:
        this.state.event?.langage === "fr"
          ? "Enregistrer et quitter"
          : "Save and quit",
    };

    if (this.state.finished) {
      return (
        <>
          <div
            className="p-3"
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "15px",
              backgroundColor: this.state.colors?.statement_background_color,
            }}
          >
            {this.state.event.event_end_message && (
              <span
                className="text-light fw-bold "
                style={{
                  fontSize: "1.5em",
                }}
                dangerouslySetInnerHTML={{
                  __html: this.state.event.event_end_message,
                }}
              ></span>
            )}
            {/* <span className="text-light fw-bold">
            {translations.thanksForParticipation}
          </span> */}
          </div>
          {this.state.event.event_end_contact && (
            <p className="mt-2">{translations.contact}</p>
          )}
        </>
      );
    } else if (this.state.loading)
      return (
        <div
          className="p-3"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      );
    else if (this.state.waiting) {
      return (
        <div
          className="p-3"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "15px",
            backgroundColor: this.state.colors?.header_background_color,
          }}
        >
          <span className="text-light pb-3">{translations.pleaseWait}</span>
          <Spinner animation="border" variant="light" />
        </div>
      );
    } else {
      return (
        <>
          <div
            className="pb-3"
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                flexDirection: "column",
              }}
            >
              {this.state.current_statement.session_subtitle && (
                <div className="mb-2">
                  <h4>
                    <b>{this.state.current_statement.session_subtitle}</b>
                  </h4>
                </div>
              )}
              <div
                className="mb-2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h4 style={{ padding: 0, margin: 0 }}>
                  {translations.statementToVote}
                </h4>
                <div
                  style={{
                    display: "flex",
                    width: "2em",
                    height: "2em",
                    aspectRatio: 1,
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="bg-light ms-2"
                >
                  <span className="fs-7 fw-bolder">
                    {this.state.current_statement.order_number}
                  </span>
                </div>
              </div>
              <Spring
                key={this.state.current_statement?.id}
                from={{ opacity: 0, transform: "scale(0.8)" }}
                to={{ opacity: 1, transform: "scale(1)" }}
              >
                {(props) => (
                  <animated.div
                    style={{
                      ...props,
                      display: "flex",
                      borderRadius: "15px",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      backgroundColor:
                        this.state.colors?.statement_background_color,
                      boxShadow: "0px 2px 20px -10px rgba(0,0,0,0.2)",
                    }}
                    className="p-3 mb-4"
                  >
                    <span className="fs-4 fw-bold text-light">
                      {this.formatStatement(
                        this.state.current_statement.statement_string
                      )}
                    </span>
                  </animated.div>
                )}
              </Spring>
              {this.state.current_statement.image_url && (
                <div className="mb-3">
                  <img
                    style={{ width: "100%" }}
                    src={this.state.current_statement.image_url}
                  ></img>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <QuestionCircleFill
                    className="me-2"
                    size={24}
                  ></QuestionCircleFill>
                  <span className="fs-6 fw-bolder">
                    {translations.howMuchAgree}
                  </span>
                </div>
                {this.generateCircles()}
                {this.state.event?.with_comments && (
                  <div
                    className="mt-3"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <Form.Group controlId="formBasicComment" className="w-100">
                      <Form.Label>{translations.comments}</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder={translations.commentsPlaceholder}
                        value={this.state.comments}
                        onChange={(e) =>
                          this.setState({ comments: e.target.value })
                        }
                      />
                    </Form.Group>
                  </div>
                )}

                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  {this.state.current_statement.order_number > 1 &&
                    !this.state.event?.with_presenter && (
                      <LoadingButton
                        variant="dark"
                        size="lg"
                        className="me-3"
                        onClick={this.handlePrevious.bind(this)}
                      >
                        {translations.previous}
                      </LoadingButton>
                    )}

                  <LoadingButton
                    variant="dark"
                    size="lg"
                    onClick={this.handleVote.bind(this)}
                    disabled={this.state.idVote === null}
                  >
                    {translations.vote}
                  </LoadingButton>
                </div>
                {!this.state.event?.with_presenter && (
                  <div
                    className="mt-2"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <LoadingButton
                      variant="light"
                      size="sm"
                      onClick={() => {
                        this.props.navigate(
                          "/events/" +
                            this.props.params.unique_str +
                            "/voter/" +
                            this.state.user.link
                        );
                      }}
                    >
                      {translations.saveAndQuit}
                    </LoadingButton>
                  </div>
                )}

                <p className="mt-2">
                  <i>{translations.infoDelphi}</i>
                </p>
              </div>
            </div>
            {this.state.event && this.state.event.legal_notice && (
              <div
                className="text-center mb-2"
                style={{
                  fontSize: "0.8rem",
                  color: this.state.colors?.text_color || "#666",
                }}
              >
                {this.state.event.legal_notice}
              </div>
            )}
          </div>
        </>
      );
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "calc(var(--vh, 1vh) * 100)",
          position: "relative",
          paddingBottom: "54px",

          backgroundColor: this.state.colors?.background_color,
          overflowX: "hidden",
        }}
      >
        {this.state.event && (
          <img
            src={
              this.state.event
                ? BASE_URL +
                  "/events/" +
                  this.state.event.id +
                  "/data/voter_footer"
                : null
            }
            style={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              height: "54px",
              objectFit: "fill",
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.style.display = "none";
            }}
          />
        )}
        {this.state.event && (
          <VoterNavBar
            text={
              !this.state.finished && this.state.current_statement?.session_name
            }
            backgroundColor={this.state.colors?.header_background_color}
          ></VoterNavBar>
        )}

        <Container
          style={{ maxWidth: "800px" }}
          fluid
          className="px-3 pt-3 flex-grow-1 d-flex flex-column"
        >
          {this.state.event?.with_presenter && (
            <Toast show={this.state.showToast}>
              <Toast.Header>
                <strong className="me-auto">Thank you</strong>
              </Toast.Header>
              <Toast.Body>Your reply has been sent!</Toast.Body>
            </Toast>
          )}

          <Alert show={this.state.error} variant={"danger"}>
            <Alert.Heading>Error</Alert.Heading>
            <p>{"An error occured : " + this.state.error_message}</p>
            <hr />
          </Alert>
          {!this.state.loading &&
            this.state.user &&
            this.state.user.username && (
              <div className="mb-4">
                <span className="fs-6">Pseudo: </span>
                <Badge bg="dark">{this.state.user.username}</Badge>
              </div>
            )}
          {this.renderPage()}
        </Container>
      </div>
    );
  }
}

export default withRouter(withTheme(PresenterSession));
