import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Admin from "./components/Admin";
import Presenter from "./components/Presenter";
import Voter from "./components/Voter";
import ModifyEvent from "./components/ModifyEvent";
import PresenteSession from "./components/PresenterSession";
import VoterRegister from "./components/VoterRegister";
import VoterSession from "./components/VoterSession";
import ModifyProject from "./components/ModifyProject";
import ThemeProvider from "./ThemeProvider";
import Fallback from "./components/Fallback";
import "./custom.scss";
import PresenterViews from "./components/PresenterViews";

function App() {
  return (
    <Router>
      <ThemeProvider>
        <Routes>
          <Route path="/admin" element={<Admin />} />
          <Route
            path="/admin/project/:project_id/event/:event_id"
            element={<ModifyEvent />}
          />
          <Route
            path="/admin/project/:project_id"
            element={<ModifyProject />}
          />

          <Route path="/events/:unique_str/presenter" element={<Presenter />} />
          <Route
            path="/events/:unique_str/presenter/session"
            element={<PresenteSession />}
          />
          <Route
            path="/events/:unique_str/voter/:unique_user_link"
            element={<Voter />}
          />
          <Route path="/events/:unique_str/voter" element={<Voter />} />
          <Route
            path="/events/:unique_str/voter/session"
            element={<VoterSession />}
          />
          <Route
            path="/events/:unique_str/voter/register"
            element={<VoterRegister />}
          />
          <Route
            path="/admin/project/:project_id/presenter_view"
            element={<PresenterViews level="project" />}
          />
          <Route
            path="/admin/project/:project_id/event/:event_id/presenter_view"
            element={<PresenterViews level="event" />}
          />
          <Route path="*" element={<Fallback />} />

          {/* Optionally, add a fallback route */}
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
