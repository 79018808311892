import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { withRouter } from "../withRouter";
import {
  authVoter,
  createAnswer,
  createVoter,
  getEvent,
  getEventColors,
  getQuestionsEvent,
  updateUserStatus,
} from "../api";
import Alert from "react-bootstrap/Alert";
import { withTheme } from "../withTheme";
import VoterNavBar from "./VoterNavBar.js";
import bandesPhoviaH from "../assets/bandes_phovia_h.svg";
import LoadingButton from "./LoadingButton";
import { BASE_URL } from "../config.js";
import { Spinner } from "react-bootstrap";
class VoterRegister extends Component {
  state = {
    questions: [],
    answers: {},

    username: null,
    showRegisterAlert: "",
    isUsernameValid: false,
    allQuestionsAnswered: true,
    conditionNotMet: false,
  };
  vh = window.innerHeight * 0.01;
  handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    this.setState((prevState) => {
      let petsType = [...prevState.petsType];
      if (checked && !petsType.includes(value)) {
        petsType.push(value);
      } else if (!checked && petsType.includes(value)) {
        petsType = petsType.filter((item) => item !== value);
      }

      return { petsType };
    });
  };

  allQuestionsAnswered = () => {
    return this.state.questions.every((question) => {
      if (question.mandatory) {
        const answer = this.state.answers[question.id]?.answer;
        if (Array.isArray(answer)) {
          return answer.length > 0; // For checkbox type questions, check if at least one option is selected
        } else {
          return answer !== undefined && answer !== ""; // For other types, check if the answer is not undefined or empty
        }
      }
      return true;
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "username") {
      this.setState({
        isUsernameValid: event.target.value.length > 4,
      });
    }
  };

  handleAnswerChange = (event, questionId, type) => {
    const { value, checked } = event.target;

    if (type === "checkbox") {
      this.setState((prevState) => {
        let answers = { ...prevState.answers };
        let answer = answers[questionId]?.answer || [];

        if (checked) {
          answer.push(value);
        } else {
          answer = answer.filter((option) => option !== value);
        }

        answers[questionId] = { answer };

        return { answers };
      });
    } else {
      this.setState({
        answers: {
          ...this.state.answers,
          [questionId]: { answer: value },
        },
      });
    }
  };

  async componentDidMount() {
    window.addEventListener("resize", this.setVHValue);

    this.setState({ loading: true });
    this.setVHValue();

    const colors = await getEventColors(this.props.location.state.event_id);

    const questions = await getQuestionsEvent(
      this.props.location.state.event_id
    );

    this.setState({
      colors,
      questions: questions.sort((a, b) => a.order - b.order),
      loading: false,
    });
  }

  componentWillUnmount() {
    // Remove the event listener when the component is unmounted
    window.removeEventListener("resize", this.setVHValue);
  }
  setVHValue = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  allQuestionsValid() {
    return this.state.questions.every((question) => {
      const answer = this.state.answers[question.id]?.answer;
      if (question.mandatory) {
        switch (question.type) {
          case "text input":
            return answer !== undefined && answer !== "";
          case "number input":
            return answer !== undefined && answer !== "";
          case "dropdown":
            return answer !== undefined && answer !== "";
          case "radio":
            return question.options.includes(answer);
          case "checkbox":
            return Array.isArray(answer) && answer.length > 0;
          default:
            return false;
        }
      }
      return true;
    });
  }

  renderQuestions() {
    return this.state.questions.map((question) => (
      <Form.Group className="mb-2" key={question.id}>
        <Form.Label>{question.question}</Form.Label>
        {question.type === "text input" && (
          <Form.Control
            type="text"
            isInvalid={
              !this.state.allQuestionsAnswered
                ? question.mandatory && !this.state.answers[question.id]?.answer
                : false
            }
            value={this.state.answers[question.id]?.answer || ""}
            onChange={(e) => this.handleAnswerChange(e, question.id)}
          />
        )}
        {question.type === "number input" && (
          <Form.Control
            type="number"
            min="0"
            isInvalid={
              !this.state.allQuestionsAnswered
                ? question.mandatory && !this.state.answers[question.id]?.answer
                : false
            }
            value={this.state.answers[question.id]?.answer || ""}
            onChange={(e) => {
              if (e.target.value >= 0) {
                this.handleAnswerChange(e, question.id);
              }
            }}
          />
        )}
        {["radio", "checkbox"].includes(question.type) &&
          question.options.map((option, index) => (
            <Form.Check
              type={question.type}
              label={option}
              key={index}
              value={option}
              isInvalid={
                !this.state.allQuestionsAnswered
                  ? question.mandatory &&
                    !this.state.answers[question.id]?.answer
                  : false
              }
              checked={
                question.type === "checkbox"
                  ? this.state.answers[question.id]?.answer?.includes(option) ||
                    false
                  : this.state.answers[question.id]?.answer === option
              }
              onChange={(e) =>
                this.handleAnswerChange(e, question.id, question.type)
              }
            />
          ))}
        {question.type === "dropdown" && (
          <Form.Control
            as="select"
            isInvalid={
              !this.state.allQuestionsAnswered
                ? question.mandatory && !this.state.answers[question.id]?.answer
                : false
            }
            value={this.state.answers[question.id]?.answer || ""}
            onChange={(e) => this.handleAnswerChange(e, question.id)}
          >
            <option value="">Select...</option>
            {question.options.map((option, index) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))}
          </Form.Control>
        )}
      </Form.Group>
    ));
  }

  checkConditions() {
    return this.state.questions.every((question) => {
      const answer = this.state.answers[question.id]?.answer;
      // Check for no conditions
      const noConditionsForNumberInput =
        question.condition_int === null ||
        question.condition_int_operator === "";
      const noConditionsForOthers =
        !question.conditions ||
        question.conditions.every((condition) => condition === "");
      let conditionValid = true; // Assume condition is valid initially

      switch (question.type) {
        case "text input":
          // No conditions allowed for text input
          console.log(
            `Question ID ${question.id}: No conditions for text input, always valid.`
          );
          conditionValid = true;
          break;
        case "number input":
          if (noConditionsForNumberInput) {
            console.log(
              `Question ID ${question.id}: No conditions for number input, valid.`
            );
            conditionValid = true;
          } else if (answer === undefined || answer === "") {
            console.log(
              `Question ID ${question.id}: Answer undefined or empty for number input, invalid.`
            );
            conditionValid = false;
          } else {
            const answerNumber = parseInt(answer, 10);
            switch (question.condition_int_operator) {
              case "inferior":
                conditionValid = answerNumber < question.condition_int;
                break;
              case "inferior equal":
                conditionValid = answerNumber <= question.condition_int;
                break;
              case "superior":
                conditionValid = answerNumber > question.condition_int;
                break;
              case "superior equal":
                conditionValid = answerNumber >= question.condition_int;
                break;
              case "equal":
                conditionValid = answerNumber === question.condition_int;
                break;
              default:
                conditionValid = false;
                break;
            }
            console.log(
              `Question ID ${question.id}: Number input condition checked, valid: ${conditionValid}`
            );
          }
          break;
        case "radio":
          conditionValid =
            noConditionsForOthers || question.conditions.includes(answer);
          console.log(
            `Question ID ${question.id}: Radio input condition checked, valid: ${conditionValid}`
          );
          break;
        case "checkbox":
          if (noConditionsForOthers) {
            console.log(
              `Question ID ${question.id}: No conditions for checkbox, valid.`
            );
            conditionValid = true;
          } else if (!Array.isArray(answer)) {
            console.log(
              `Question ID ${question.id}: Answer not an array for checkbox, invalid.`
            );
            conditionValid = false;
          } else {
            console.log(question.conditions);
            conditionValid = question.conditions.every((condition) =>
              answer.includes(condition)
            );
            console.log(
              `Question ID ${question.id}: Checkbox conditions checked, valid: ${conditionValid}`
            );
          }
          break;
        default:
          console.log(
            `Question ID ${question.id}: Default case, always valid.`
          );
          conditionValid = true;
          break;
      }
      return conditionValid;
    });
  }

  handleSubmit = async () => {
    try {
      let canProceed =
        this.props.location.state.position ===
          this.props.location.state.form_placement &&
        this.allQuestionsAnswered();

      if (
        this.props.location.state.with_presenter &&
        this.props.location.state.position === "start"
      ) {
        canProceed = canProceed && this.state.username?.length >= 4;
      }

      if (!canProceed) {
        this.setState({
          allQuestionsAnswered: false,
        });
        return;
      }

      if (
        this.props.location.state.position ==
          this.props.location.state.form_placement &&
        !this.checkConditions()
      ) {
        this.setState({
          conditionNotMet: true,
        });
        return;
      }
      if (this.props.location.state.position == "start") {
        if (this.props.location.state.with_presenter) {
          const voterToCreate = {
            username: this.state.username,
            current_statement_number: 1,
            link: this.props.location.state.unique_link,
          };
          const hasNullValue = Object.values(voterToCreate).some(
            (value) => value === null
          );
          if (hasNullValue) {
            return;
          }
          const voter = await createVoter(
            this.props.location.state.event_id,
            voterToCreate
          );
        }

        await authVoter(
          this.props.location.state.event_id,
          this.state.username,
          this.props.location.state.unique_link
        );
      }

      if (
        this.props.location.state.form_placement ==
        this.props.location.state.position
      ) {
        for (const [questionId, answerObj] of Object.entries(
          this.state.answers
        )) {
          let answer = answerObj.answer;
          if (Array.isArray(answer)) {
            answer = answer.join(",");
          }
          await createAnswer(questionId, { answer });
        }
        if (this.props.location.state.position == "end") {
          await updateUserStatus(
            this.props.location.state.event_id,
            "finished"
          );
        }
      }
      this.props.navigate(
        "/events/" + this.props.location.state.unique_str + "/voter/session"
      );
    } catch (error) {
      console.log(error);
      this.setState({
        error: error.response.data.detail,
        loading: false,
        showRegisterAlert: "danger",
      });
    }
  };

  render() {
    const lang = this.props.location.state.langage;
    const translations = {
      error: lang === "fr" ? "Erreur" : "Error",
      success: lang === "fr" ? "Succès" : "Success",
      close: lang === "fr" ? "Fermer" : "Close",
      fillInfo:
        this.props.location.state.position == "start"
          ? lang === "fr"
            ? "Informations générales"
            : "General information"
          : lang === "fr"
          ? "Afin de terminer le vote, répondez aux questions suivantes:"
          : "Please fill in the following information:",

      fillInfoDescription:
        lang === "fr"
          ? "Afin de pouvoir apporter dans l’article que nous rédigerons quelques informations sur la base des votants et donner ainsi encore plus de poids à ses conclusions, pourriez-vous s’il vous plait répondre aux quelques questions très simples sur votre expérience et votre pratique ci-dessous. Les réponses sont bien entendu totalement anonymes."
          : "In order to be able to bring some information into the article that we will write based on the voters and thus give even more weight to its conclusions, could you please answer the few very simple questions about your experience and practice below? The answers are of course completely anonymous.",
      chooseUsername:
        lang === "fr"
          ? "Entrez un pseudo vous permettant en cas de déconnexion de vous reconnecter à votre session Delphi."
          : "Finally, to start the real-time voting process and ensure you can reconnect if needed, please choose a username you will remember.",
      chooseUsernameDetail:
        lang === "fr"
          ? "Ce pseudo est à retenir et ne doit pas permettre de vous identifier personnellement."
          : "This username should be memorable and should not allow you to be personally identified.",
      usernamePlaceholder: lang === "fr" ? "Nom d'utilisateur" : "Username",
      usernameInvalid:
        lang === "fr"
          ? "Le nom d'utilisateur doit comporter plus de 4 caractères."
          : "Username must be more than 4 characters.",
      register:
        this.props.location.state.position == "start"
          ? lang === "fr"
            ? this.props.location.state.with_presenter
              ? "Commencer"
              : "Continuer"
            : this.props.location.state.with_presenter
            ? "Start"
            : "Continue"
          : lang === "fr"
          ? "Terminer"
          : "Finish",
      conditionNotMet:
        lang === "fr"
          ? "Basé sur vos réponses, vous n'êtes pas éligible au vote Delphi."
          : "Based on your answers, you are not eligible for the Delphi vote.",

      questionMandatory:
        lang === "fr"
          ? "Les questions avec un (*) sont obligatoires."
          : "Questions with (*) are mandatory.",
    };

    console.log(this.props.navigate);
    console.log(this.props.location.state);
    console.log(this.props.location);
    if (this.state.loading) {
      return (
        <Container className="d-flex justify-content-center align-items-center">
          <Spinner />
        </Container>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "calc(var(--vh, 1vh) * 100)", // Updated minHeight
          paddingBottom: "54px",
          backgroundColor: this.state.colors?.background_color,
          position: "relative",
          overflowX: "hidden",
        }}
      >
        <img
          src={
            BASE_URL +
            "/events/" +
            this.props.location.state.event_id +
            "/data/voter_footer"
          }
          onError={(e) => {
            e.target.style.display = "none";
          }}
          style={{
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            height: "54px",
            objectFit: "fill",
          }}
        />
        <VoterNavBar
          backgroundColor={this.state.colors?.header_background_color}
          img={
            BASE_URL +
            "/events/" +
            this.props.location.state.event_id +
            "/data/logo"
          }
        ></VoterNavBar>

        <Container
          style={{ maxWidth: "800px", marginBottom: "10px" }}
          fluid
          className="px-3 pt-3 flex-grow-1 d-flex flex-column"
        >
          <Alert
            show={this.state.showRegisterAlert !== ""}
            variant={this.state.showRegisterAlert}
          >
            <Alert.Heading>
              {this.state.showRegisterAlert === "danger"
                ? translations.error
                : translations.success}
            </Alert.Heading>
            <p>{"Error when registering voter: " + this.state.error}</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => this.setState({ showRegisterAlert: "" })}
                variant={"outline-" + this.state.showRegisterAlert}
              >
                {translations.close}
              </Button>
            </div>
          </Alert>
          {!this.state.conditionNotMet ? (
            <>
              <h2 className="text-align-center">{translations.fillInfo}</h2>
              <p className="mb-2 " style={{ fontStyle: "italic" }}>
                {translations.fillInfoDescription}
              </p>
              <Form>
                {this.props.location.state.form_placement ==
                  this.props.location.state.position && this.renderQuestions()}
                {this.props.location.state.form_placement ==
                  this.props.location.state.position && (
                  <span className="fw-medium" style={{ fontStyle: "italic" }}>
                    {translations.questionMandatory}
                  </span>
                )}

                {this.props.location.state.position == "start" &&
                  this.props.location.state.with_presenter && (
                    <Form.Group className="mt-4" controlId="username">
                      <Form.Label className="fw-bold">
                        {translations.chooseUsername}
                        <br />
                        <span
                          className="fw-medium"
                          style={{ fontStyle: "italic" }}
                        >
                          {translations.chooseUsernameDetail}
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="username"
                        placeholder={translations.usernamePlaceholder}
                        value={this.state.username}
                        onChange={this.handleChange}
                        isInvalid={!this.state.isUsernameValid}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {translations.usernameInvalid}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}

                <div
                  className="mt-3"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingButton
                    onClick={this.handleSubmit.bind(this)}
                    variant="dark"
                    size="lg"
                    disabled={false}
                  >
                    {translations.register}
                  </LoadingButton>
                </div>
              </Form>
            </>
          ) : (
            <div className="text-align-center">
              <div style={{ textAlign: "left" }}>
                {this.props.location.state.event_refused_message
                  .split("\n")
                  .map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
              </div>
            </div>
          )}
          {this.props.location.state.legal_notice && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                marginBottom: "10px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.8rem",
                color: this.state.colors?.text_color || "#666",
              }}
            >
              {this.props.location.state.legal_notice}
            </div>
          )}
        </Container>
      </div>
    );
  }
}

export default withRouter(withTheme(VoterRegister));
