import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { withRouter } from "../withRouter";
import { withTheme } from "../withTheme";

class VoterNavBar extends Component {
  render() {
    return (
      <Navbar
        style={{
          backgroundColor: this.props.backgroundColor,
          minHeight: "auto",
        }}
        className="py-2"
      >
        <Container
          className={
            this.props.text
              ? "d-flex align-items-center gap-3"
              : "justify-content-center"
          }
        >
          <Navbar.Brand
            className={`fw-bold m-0 ${!this.props.text ? "mx-auto" : ""}`}
            style={{ color: "var(--light)" }}
          >
            <img
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = "none";
              }}
              alt=""
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                display: "block",
              }}
              PVoter
              src={this.props.img}
              height="60"
              className="d-inline-block"
            />
          </Navbar.Brand>
          {this.props.text && (
            <Navbar.Text
              className="fw-bold fs-4 text-center m-0"
              style={{ color: "var(--light)" }}
            >
              {this.props.text}
            </Navbar.Text>
          )}
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(withTheme(VoterNavBar));
